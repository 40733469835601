import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { API } from 'aws-amplify';
import { getAPIName } from '../config';
import handleApiErrors from '../common/handleApiErrors';
import { useSnackbar } from 'notistack';

export default function ServerPaginationGrid(props: any) {
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(5);
  const [rowCount, setRowCount] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const loadServerRows = async (
    page: number,
    pageSize: number,
    searchterm: string
  ) => {
    const queries: Array<{ name: string; value: string }> = [];
    queries.push({ name: 'limit', value: `${pageSize}` });
    queries.push({
      name: 'offset',
      value: `${pageSize * page - pageSize}`,
    });

    if (searchterm !== '')
      queries.push({
        name: 'search-term',
        value: `${searchterm}`,
      });

    let path: string = '/vehicles';
    if (queries.length >= 1) {
      const paramStrings: string[] = queries.map(
        (q) => `${encodeURIComponent(q.name)}=${encodeURIComponent(q.value)}`
      );
      path = `${path}?${paramStrings.join('&')}`;
    }

    try {
      if (searchterm !== '') {
        let results = await API.get(getAPIName(), path, {});
        return {
          body: results.body.map((item: any) => {
            return {
              ...item,
              dealershipname: item.dealership.name,
            };
          }),
          totalRecords: results.totalRecordCount,
        };
      }
      return { body: [], totalRecords: 0 };
    } catch (e) {
      handleApiErrors(e, enqueueSnackbar);
      return { body: [], totalRecords: 0 };
    }
  };

  const handlePageChange = (params: any) => {
    setPage(params.page);
  };

  React.useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      const newRows: any = await loadServerRows(
        page,
        pageSize,
        props.searchTerm
      );

      if (!active) {
        return;
      }
      setRows(newRows.body);
      setRowCount(newRows.totalRecords);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, props.searchTerm]);

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={[
          {
            field: 'dealershipname',
            headerName: 'Dealership Name',
            width: 200,
          },
          { field: 'licenseplate', headerName: 'License Plate', width: 150 },
          { field: 'state', headerName: 'State', width: 100 },
          { field: 'vin', headerName: 'Vin #', width: 200 },
        ]}
        pagination
        pageSize={pageSize}
        rowCount={rowCount}
        paginationMode="server"
        onPageChange={handlePageChange}
        onSelectionChange={(row: any) => {
          let foundRow = row.rows[0];
          if (foundRow) {
            props.handleChange({
              dealershipId: foundRow.dealershipid,
              vehicleId: foundRow.id,
            });
          }
        }}
        loading={loading}
      />
    </div>
  );
}
