import React, { useEffect, useState } from 'react';
import fileDownload from 'js-file-download';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { Invoice } from '../../_models/Invoice';
import { API } from 'aws-amplify';
import { getAPIName } from '../../config';
import { TopFormSearchOption } from '../TopFormSearch';
import TopForm, { TopFormState, StatusOption } from '../TopForm';
import handleApiErrors from '../../common/handleApiErrors';
import ReactTable, { Column, SortingRule } from 'react-table';
import moment from 'moment';
import {
  CircularProgress,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

const searchOptions: TopFormSearchOption[] = [
  { label: 'Contract', value: 'contract.contractnumber' },
  { label: 'RO#', value: 'contract.ro_number' },
  //{ label: 'License Plate', value: 'vehicle.licenseplate' },
];

const statusOptions: StatusOption[] = [
  { name: 'Charged', value: 'Charged' },
  { name: 'Created', value: 'Created' },
  { name: 'Declined', value: 'Declined' },
  { name: 'Missing_CC_Token', value: 'missing_cc_token' }
];

interface PageState {
  readonly page: number;
  readonly pageSize: number;
  readonly sortingRules: SortingRule[];
}

const defaultPageState: PageState = {
  page: 0,
  pageSize: 10,
  sortingRules: [{
    id: 'id',
    desc: false,
  }],
};

export default function Invoices() {
  const { enqueueSnackbar } = useSnackbar();

  const [csvIsLoading, setCsvIsLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageState, setPageState] = useState<PageState>(defaultPageState);
  const [searchValue, setSearchValue] = useState<string>('');
  const [topFormState, setTopFormState] = useState<TopFormState>({
    dealershipId: 0,
    endDate: null,
    searchInput: '',
    searchType: searchOptions[0],
    startDate: null,
    statusSelection: undefined,
  });

  // fetch stuff //////////////////////////////////////////////////////////////
  // invoices
  const [[invoices, totalInvoicesCount], setContracts] = React.useState<[Invoice[], number]>([[], 0]);
  const searchInvoices = async (): Promise<void> => {
    setIsLoading(true);
    const response = await getInvoices();
    setContracts([response.body, response.totalRecordCount]);
    setIsLoading(false);
  }
  useEffect(() => { searchInvoices(); }, [searchValue, pageState]);

  // handlers /////////////////////////////////////////////////////////////////
  const handleCsvButton = async (): Promise<void> => {
    setCsvIsLoading(true);
    const response = await getInvoices(false, { headers: { accept: 'text/csv' }, responseType: 'text' });
    // fileDownload(response, 'data.csv');
    const currentDate = moment().format('YYYYMMDD-hhmmss')
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(response);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "InvoiceSummary" + currentDate + fileExtension);
    setCsvIsLoading(false);
  };

  const handleTableFetchData = (state: {
    page: number;
    pageSize: number;
    sorted: SortingRule[];
  }): void => {
    setPageState({
      ...pageState,
      page: state.page,
      pageSize: state.pageSize,
      sortingRules: state.sorted,
    });
  };

  const getInvoices = async (paginate: boolean = true, apiInit: any = {}): Promise<any> => {
    const queries: Array<{ name: string; value: string }> = [];
    if (paginate) {
      queries.push({ name: 'limit', value: `${pageState.pageSize}` });
      queries.push({ name: 'offset', value: `${pageState.pageSize * pageState.page}` });
    }

    if (topFormState.dealershipId !== 0)
      queries.push({ name: 'contract.dealershipid', value: `${topFormState.dealershipId}` });
    if (topFormState.statusSelection != null)
      queries.push({ name: 'status', value: topFormState.statusSelection.value });
    if (topFormState.searchType.value !== '' && topFormState.searchInput !== '')
      queries.push({
        name: topFormState.searchType.value,
        value: topFormState.searchInput,
      });
    if (topFormState.endDate != null)
      queries.push({ name: 'lt-insertedtimestamp', value: moment(topFormState.endDate).format('YYYY-MM-DD').toString() });
    if (topFormState.startDate != null)
      queries.push({ name: 'gte-insertedtimestamp', value: moment(topFormState.startDate).startOf('month').format('YYYY-MM-DD').toString() });

    // sort
    let i: number = 0;
    for (let sortingRule of pageState.sortingRules)
      if (sortingRule.id != 'paymentlink') {   //added this check as filter using paymentlink is not possible
        queries.push({
          name: `orderBy-${i++}${sortingRule.desc === true ? '-desc' : ''}`,
          value: `${sortingRule.id}`,
        });
      }

    let path: string = '/invoices';
    if (queries.length >= 1) {
      const paramStrings: string[] = queries.map(q => `${encodeURIComponent(q.name)}=${encodeURIComponent(q.value)}`);
      path = `${path}?${paramStrings.join("&")}`;
    }

    try {
      return await API.get(getAPIName(), path, apiInit);
    } catch (e) {
      handleApiErrors(e, enqueueSnackbar);
    }
  };

  // JSX.Element preprocessing /////////////////////////////////////////////////
  let pages: number =
    totalInvoicesCount === 0 || pageState.pageSize === 0
      ? 0
      : Math.trunc(totalInvoicesCount / pageState.pageSize);
  if (
    pages > 0 &&
    totalInvoicesCount > pageState.pageSize &&
    totalInvoicesCount % pageState.pageSize > 0
  )
    ++pages;

  // render ///////////////////////////////////////////////////////////////////
  return (
    <div className="Invoices">
      <Grid container>
        <Grid item xs={9}>
          <Typography variant="h4" align="left">
            Invoices
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Button size="small" variant="outlined" onClick={handleCsvButton}>
            {!csvIsLoading ? 'Download Excel' :
              <CircularProgress size={24} />
            }
          </Button>
        </Grid>
      </Grid>

      <TopForm
        onChange={setTopFormState}
        onSearch={searchInvoices}
        searchOptions={searchOptions}
        state={topFormState}
        statusOptions={statusOptions}
      />

      <Grid container>
        <Grid xs={12}>
          <ReactTable
            className="-striped -highlight"
            columns={columns}
            data={invoices}
            defaultPageSize={10}
            defaultSorted={defaultPageState.sortingRules}
            loading={isLoading}
            manual
            onFetchData={handleTableFetchData}
            pages={pages}
          />
        </Grid>
      </Grid>

    </div>
  );
}

const columns: Column[] = [
  {
    Header: 'Invoice Number',
    accessor: 'id',
    Cell: (props: any) => {
      return props.original.bucketpath && (
        <a
          target="_blank"
          href={
            'https://tollaid-emailtemplates.s3.us-east-2.amazonaws.com/prod/' +
            props.original.bucketpath
          }
        >
          {props.original.id}
        </a>
      )
    }

  },
  {
    Header: 'Contract #',
    accessor: 'contract.contractnumber'
  },
  {
    Header: 'RO #',
    accessor: 'contract.ro_number'
  },
  {
    Header: 'First Name',
    accessor: 'contract.customer.firstname'
  },
  {
    Header: 'Last Name',
    accessor: 'contract.customer.lastname'
  },
  {
    Header: 'Email',
    accessor: 'contract.customer.emailaddress'
  },
  {
    accessor: (row: any) => row.amount != null ? `$${(+row.amount).toFixed(2)}` : '—',
    Header: 'Amount',
    id: 'amount',
  },
  {
    Header: 'Status',
    accessor: 'status'
  },
  {
    Header: 'PaymentLink Status',
    accessor: 'paymentlink',
    Cell: (props: any) => {
      var paymentlinks = props.original.paymentlink
      if (paymentlinks.length > 0) {
        var stat = paymentlinks.sort((a: any, b: any) => b.id - a.id)[0].status
      }
      return stat ? stat : "-"
    }
  },
  {
    Header: 'Dealership',
    accessor: 'contract.dealership.name'
  },
  {
    Header: 'Group',
    accessor: 'contract.dealership.dealershipgroup.name'
  },
  {
    Header: 'Payment Gateway Fee',
    accessor: 'pgw_fee_total'
  }
];