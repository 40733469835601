import React, { useState , useEffect} from 'react';
import { Button, Grid, MenuItem, Select, TextField } from '@material-ui/core';
import { API } from 'aws-amplify';
import { getAPIName } from '../../config';
import useDebounce from '../../components/helpers/useDebounce'
import { generateHandleChange } from '../../common/state-setter-generator';
import ServerPaginationGrid from '../../components/vehicle-search.component';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import { navigate } from '@reach/router';
import handleApiErrors from '../../common/handleApiErrors';
import { useSnackbar } from 'notistack';

type QuickToll = {
    type: number;
    dealershipId?: number;
    vehicleId?: number;
    description?: string;
    amount?: number;
    adminfees?: number;
    searchTerm: string;
    transactiontimestamp?: Date | null;
};




const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        spacedButton: {
            marginRight: '2rem',
        },
    })
);

let TollCreate = () => {
    const classes = useStyles();
    const [quickTollState, setQuickTollState] = useState<
        QuickToll
    >({
        type: 1,
        dealershipId: undefined,
        vehicleId: undefined,
        description: undefined,
        amount: undefined,
        adminfees: undefined,
        transactiontimestamp: new Date(Date.now()),
        searchTerm: ''
    });
    const { enqueueSnackbar } = useSnackbar();

    const handleChange = generateHandleChange(
        quickTollState,
        setQuickTollState
    );

    const updateVehicle = (vehicleInfo: any) => {
        setQuickTollState({
            ...quickTollState,
            ...vehicleInfo,
        });
    };

    const debouncedSearchTerm = useDebounce(quickTollState.searchTerm, 500);

    const [selectedDate, setSelectedDate] = React.useState<Date | null>(
        new Date(Date.now())
    );

    const handleDateChange = (date: Date | null, type: 'transactiontimestamp') => {
        setSelectedDate(date);
        handleChange(type)(date);
    };

    const [qucikTollTypesList, setQucikTollTypesList] = useState([])
   
    const handleSubmitToll = async() => {

        try {
            let response;
            let insertable_toll_flag = true
            if (quickTollState.vehicleId === undefined) {
                enqueueSnackbar('select the vehicle', { variant: 'error' });
                insertable_toll_flag = false
            }
            if (!validator(quickTollState.amount)) {
                enqueueSnackbar('invalid amount', { variant: 'error' });
                insertable_toll_flag = false
            }
            if (quickTollState.description === undefined) {
                enqueueSnackbar('description is required', { variant: 'error' });
                insertable_toll_flag = false
            }
            if (insertable_toll_flag) {
                let transaction_timestamp_in_utc = quickTollState.transactiontimestamp && quickTollState.transactiontimestamp.toUTCString()
                let toll = {
                    'amount': quickTollState.amount !== undefined ? quickTollState.amount : 0,
                    'admin_fees':quickTollState.adminfees !== undefined ? quickTollState.adminfees : 0,
                    'dealershipid': quickTollState.dealershipId,
                    'description_entry': quickTollState.description,
                    'description_exit': quickTollState.description,
                    'posted_time': transaction_timestamp_in_utc  ,
                    'postingdate': null,
                    'toll_status_id': 9, 
                    'toll_type_id': quickTollState.type,
                    'vehicleid': quickTollState.vehicleId,
                    'entry_time': null,
                    'exit_time': null,
                    'transaction_time': transaction_timestamp_in_utc,
                }
                response = await API.post(getAPIName(), '/tolls', {
                    body: toll,
                });
                enqueueSnackbar('Toll Inserted!', { variant: 'success' });
                navigate('/tolls');

            } 
        } catch (err) {
            handleApiErrors(err, enqueueSnackbar);
        }

    }

    const getTolltypes = async() =>{
        let path = '/tolltypes';
    try {
    const response: any = await API.get(getAPIName(), path, {});
    setQucikTollTypesList(response.body);
    } catch (e) {
    console.log(e);
    }
    }

    const quickTollTypes: JSX.Element[] = qucikTollTypesList && qucikTollTypesList.map((type) => (
        <MenuItem key={type['description']} value={type['id']}>
            {type['description']}
        </MenuItem>
    ));

    useEffect(() => { getTolltypes(); 
    
    }, []);

    const validator = (val:any) =>{
        const pattern=/^(?:[1-9]\d*|0)?(?:\.\d+)?$/
        const res = pattern.test(val);
        return(res)
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <h2>Quick Charge</h2>
            </Grid>
            <Grid container spacing={0}>

                <Grid item xs={12} md={6}>

                    {(
                        <Grid item xs={12} spacing={2} container alignItems="center">
                            <Grid
                                item
                                xs={4}
                                className="label-margin"
                                style={{ textAlign: 'left' }}
                                justify="flex-start"
                            >
                                Transaction Time
                            </Grid>
                            <Grid item xs={8} style={{ textAlign: 'left' }}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="dateInDate"
                                        label="Date"
                                        value={selectedDate}
                                        onChange={(e: any) => handleDateChange(e, 'transactiontimestamp')}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                    <KeyboardTimePicker
                                        margin="normal"
                                        id="dateInTime"
                                        label="Time"
                                        value={selectedDate}
                                        onChange={(e: any) => handleDateChange(e, 'transactiontimestamp')}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                    )}
                    {(
                        <Grid item xs={12} spacing={2} container alignItems="center">
                            <Grid
                                item
                                xs={4}
                                className="label-margin"
                                style={{ textAlign: 'left' }}
                            >
                                Vehicle Search
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    id="vehicleSearchField"
                                    placeholder="License Plate / VIN"
                                    margin="dense"
                                    onChange={(event) =>
                                        handleChange('searchTerm')(event.target.value)
                                    }
                                    required
                                    value={quickTollState.searchTerm}
                                />
                            </Grid>
                            {debouncedSearchTerm && (
                                <Grid item xs={12}>
                                    <ServerPaginationGrid
                                        searchTerm={debouncedSearchTerm}
                                        handleChange={(vehicleInfo: any) => {
                                            updateVehicle(vehicleInfo);
                                        }}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                
                    <br />
                    <br />

                    <Grid item xs={12} spacing={2} container alignItems="center">
                        <Grid item xs={4} style={{ textAlign: 'left' }}>
                            Type
                        </Grid>
                        <Grid item xs={8}>
                            <Select
                                fullWidth
                                margin="dense"
                                placeholder="Type"
                                onChange={(event: any) => {
                                    handleChange('type')(event.target.value);
                                }}
                                value={quickTollState.type}
                                required
                            >
                                {quickTollTypes}
                            </Select>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} spacing={2} container alignItems="center">
                        <Grid item xs={4} style={{ textAlign: 'left' }}>
                            Description
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                id="descriptionField"
                                placeholder="Description"
                                margin="dense"
                                value={quickTollState.description}
                                onChange={(event) =>
                                    handleChange('description')(event.target.value)
                                }
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} spacing={2} container alignItems="center">
                        <Grid item xs={4} style={{ textAlign: 'left' }}>
                            Amount
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                id="AmountField"
                                placeholder="Amount"
                                margin="dense"
                                value={quickTollState.amount}
                                onChange={(event) =>
                                    handleChange('amount')(parseFloat(event.target.value) || undefined)
                                }
                                type="number"
                                InputProps={{
                                    inputProps: { min: 0 }
                                }}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} spacing={2} container alignItems="center">
                        <Grid item xs={4} style={{ textAlign: 'left' }}>
                            Admin Fees
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                id="adminChargeField"
                                placeholder="Admin Fees"
                                margin="dense"
                                value={quickTollState.adminfees}
                                onChange={(event: any) =>
                                    handleChange('adminfees')(parseFloat(event.target.value) || undefined)
                                }
                                type="number"
                                InputProps={{
                                    inputProps: { min: 0 }
                                }}
                                required
                            />
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                spacing={2}
                container
                justify="center"
                style={{ paddingTop: '3rem' }}
            >
                <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    className={classes.spacedButton}
                    onClick={() => navigate('/tolls')}
                >
                    Cancel
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmitToll()}
                >
                    Save
                </Button>
            </Grid>
        </Grid>
    );
};

export default TollCreate;