import * as React from 'react';
import { Router, RouteComponentProps } from '@reach/router';
import Contracts from './Pages/Contracts';
import ContractCreate from './Pages/ContractCreate';
import Dashboard from './Pages/Dashboard';
import DealershipGroups from './Pages/DealershipGroups';
import Dealerships from './Pages/Dealerships';
import Employees from './Pages/Employees';
import Fleet from './Pages/Fleet';
import HelpSupport from './Pages/HelpSupport';
import Invoices from './Pages/Invoices';
import PaperViolations from './Pages/PaperViolations';
import Reports from './Pages/Reports';
import Tolls from './Pages/Tolls';
import TollsReport from './Pages/TollsReport';
import Users from './Pages/Users';
import Vehicles from './Pages/Vehicles';
import { RouteDefinitions } from '../common/route-definitions';
import AuthorisePayment from "./Pages/AuthorisePayment";
import AuthorisePaymentSuccess from './Pages/AuthorisePaymentSuccess';
import AuthorisePaymentFail from './Pages/AuthorisePaymentFail';
import TollCreate from './Pages/AddTolls';


export const ApplicationRouter = () => (
  <Router primary={false}>
    <RouterPage
      path={RouteDefinitions.Contracts}
      pageComponent={<Contracts />}
    />
    <RouterPage
      path={RouteDefinitions.ContractCreate}
      pageComponent={<ContractCreate />}
    />
    <RouterPage
      path={RouteDefinitions.Dashboard}
      pageComponent={<Dashboard />}
    />
    <RouterPage
      path={RouteDefinitions.DealershipGroups}
      pageComponent={<DealershipGroups />}
    />
    <RouterPage
      path={RouteDefinitions.Dealerships}
      pageComponent={<Dealerships />}
    />
    <RouterPage
      path={RouteDefinitions.Employees}
      pageComponent={<Employees />}
    />
    <RouterPage path={RouteDefinitions.Fleet} pageComponent={<Fleet />} />
    <RouterPage
      path={RouteDefinitions.HelpSupport}
      pageComponent={<HelpSupport />}
    />
    <RouterPage path={RouteDefinitions.Invoices} pageComponent={<Invoices />} />
    <RouterPage
      path={RouteDefinitions.PaperViolations}
      pageComponent={<PaperViolations />}
    />
    <RouterPage path={RouteDefinitions.Reports} pageComponent={<Reports />} />
    <RouterPage path={RouteDefinitions.Tolls} pageComponent={<Tolls />} />
    <RouterPage path={RouteDefinitions.TollsReport} pageComponent={<TollsReport />} />
    <RouterPage path={RouteDefinitions.Users} pageComponent={<Users />} />
    <RouterPage path={RouteDefinitions.Users} pageComponent={<Users />} />
    <RouterPage path={RouteDefinitions.Vehicles} pageComponent={<Vehicles />} />
    <RouterPage path={RouteDefinitions.AuthorisePayment} pageComponent={<AuthorisePayment />} />
    <RouterPage path={RouteDefinitions.AuthorisePaymentSuccess} pageComponent={<AuthorisePaymentSuccess />} />
    <RouterPage path={RouteDefinitions.AuthorisePaymentFail} pageComponent={<AuthorisePaymentFail />} />
    <RouterPage path={RouteDefinitions.TollCreate} pageComponent={<TollCreate />} />

  </Router>
);

const RouterPage = (
  props: { pageComponent: JSX.Element } & RouteComponentProps
) => props.pageComponent;
