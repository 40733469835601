export enum RouteDefinitions {
  Contracts = 'contracts',
  ContractCreate = 'contracts/new',
  Dashboard = '/',
  DealershipGroups = 'dealership-groups',
  Dealerships = 'dealerships',
  Employees = 'employees',
  Fleet = 'fleet',
  HelpSupport = 'help-support',
  Invoices = 'invoices',
  PaperViolations = 'paper-violations',
  Reports = 'reports',
  Tolls = 'tolls',
  TollsReport = 'tollreports',
  Users = 'users',
  Vehicles = 'vehicles',
  AuthorisePayment = 'authorise',
  AuthorisePaymentSuccess = 'authorisepaymentsuccess',
  AuthorisePaymentFail = 'authorisepaymentfail',
  TollCreate = 'toll/new',
}
