import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import fileDownload from 'js-file-download';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { API } from 'aws-amplify';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { library } from '@fortawesome/fontawesome-svg-core';
import TopForm, { TopFormState, StatusOption } from '../TopForm';
import { TopFormSearchOption } from '../TopFormSearch';
import ServerPaginationGrid from '../vehicle-search.component';
import useDebounce from '../helpers/useDebounce';
import Contract from '../../_models/Contract';
import handleApiErrors from '../../common/handleApiErrors';
import { generateHandleChange } from '../../common/state-setter-generator';
import {
  CircularProgress,
  Button,
  Grid,
  Switch,
  Typography,
  Modal,
  TextField,
  Select,
  MenuItem,
} from '@material-ui/core';
import moment from 'moment';
import ReactTable, { Column, SortingRule } from 'react-table';
import { getAPIName } from '../../config';
import { useSnackbar } from 'notistack';
import { navigate } from '@reach/router';
import {
  faEdit,
  faCalendarAlt,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCheck,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
library.add(
  faEdit,
  faCalendarAlt,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCheck,
  faTimes
);

const DEFAULT_PAGE_SIZE: number = 10;

const searchOptions: TopFormSearchOption[] = [
  { label: 'Contract', value: 'contractnumber' },
  { label: 'RO#', value: 'ro_number' },
  { label: 'License Plate', value: 'vehicle.licenseplate' },
];

const statusOptions: StatusOption[] = [
  { name: 'Open', value: 'Open' },
  { name: 'Closed', value: 'Closed' },
  { name: 'Unpaid', value: 'Unpaid' },
];

const authTokenStatusOptions: StatusOption[] = [
  { name: 'Found', value: 'Found' },
  { name: 'Not Found', value: 'Not Found' }
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    table: {
      minWidth: 650,
    },
    paper: {
      position: 'absolute',
      width: '50%',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    spacedButton: {
      marginRight: '2rem',
    },
  })
);

interface PageState {
  readonly page: number;
  readonly pageSize: number;
  readonly sortingRules: SortingRule[];
}

const defaultPageState: PageState = {
  page: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  sortingRules: [
    {
      id: 'id',
      desc: false,
    },
  ],
};

export default function Contracts() {
  const location = useLocation() as any
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [csvIsLoading, setCsvIsLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageState, setPageState] = useState<PageState>(defaultPageState);
  const [searchValue, setSearchValue] = useState<string>('');
  const [topFormState, setTopFormState] = useState<TopFormState>({
    dealershipId: location.state.dealershipId ? location.state.dealershipId : 0,
    endDate: location.state.endDate ? location.state.endDate : null,
    searchInput: '',
    searchType: searchOptions[0],
    startDate: location.state.startDate ? location.state.startDate : null,
    statusSelection: undefined,
    authTokenStatusSelection: location.state.authTokenStatusSelection ? location.state.authTokenStatusSelection : undefined,
  });
  // fetch stuff //////////////////////////////////////////////////////////////
  // contracts
  const [[contracts, totalContractCount], setContracts] = React.useState<
    [Contract[], number]
  >([[], 0]);

  const searchContracts = async (): Promise<void> => {
    setIsLoading(true);
    const response = await getContracts();
    setContracts([response.body, response.totalRecordCount]);
    setIsLoading(false);
  };
  useEffect(() => {
    searchContracts();
  }, [searchValue, pageState]);

  // handlers /////////////////////////////////////////////////////////////////
  const handleCsvButton = async (): Promise<void> => {
    setCsvIsLoading(true);
    const response = await getContracts(false, {
      headers: { accept: 'text/csv' },
      responseType: 'text',
    });
    // fileDownload(response, 'data.csv');
    const currentDate = moment().format('YYYYMMDD-hhmmss')
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(response);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "ContractSummary" + currentDate + fileExtension);
    setCsvIsLoading(false);
  };

  const handleTableFetchData = (state: {
    page: number;
    pageSize: number;
    sorted: SortingRule[];
  }): void => {
    setPageState({
      ...pageState,
      page: state.page,
      pageSize: state.pageSize,
      sortingRules: state.sorted,
    });
  };

  const updateContract = (id: string, params: any) => {
    let apiName = getAPIName();
    let path = `/contracts/${id}`;
    return API.post(apiName, path, { body: params });
  };

  const updateContractStatus = async (cell: any) => {
    const {
      original: { id, status },
      index,
    } = cell;
    // Update the db record
    let newStatus = status === 'Waived' ? 'Unpaid' : 'Waived';
    await updateContract(id, { type: newStatus });

    // Update the local state
    let newContractList = [...contracts];
    newContractList[index].status =
      newContractList[index].status === 'Waived' ? 'Unpaid' : 'Waived';
    setContracts([newContractList, totalContractCount]);
  };

  const getContracts = async (
    paginate: boolean = true,
    apiInit: any = {}
  ): Promise<any> => {
    const queries: Array<{ name: string; value: string }> = [];
    if (paginate) {
      queries.push({ name: 'limit', value: `${pageState.pageSize}` });
      queries.push({
        name: 'offset',
        value: `${pageState.pageSize * pageState.page}`,
      });
    }

    if (topFormState.dealershipId !== 0)
      queries.push({
        name: 'dealershipid',
        value: `${topFormState.dealershipId}`,
      });
    if (topFormState.statusSelection != null)
      queries.push({
        name: 'status',
        value: topFormState.statusSelection.value,
      });

      if (topFormState.authTokenStatusSelection != null)
      queries.push({
        name: 'auth_token_status',
        value: topFormState.authTokenStatusSelection.value,
      });

    if (topFormState.searchType.value !== '' && topFormState.searchInput !== '')
      queries.push({
        name: topFormState.searchType.value,
        value: topFormState.searchInput,
      });
    if (topFormState.endDate != null)
      queries.push({
        name: 'lt-datein',
        value: topFormState.endDate.toISOString(),
      });
    if (topFormState.startDate != null)
      queries.push({
        name: 'gte-dateout',
        value: topFormState.startDate.toISOString(),
      });

    // sort
    let i: number = 0;
    for (let sortingRule of pageState.sortingRules)
      queries.push({
        name: `orderBy-${i++}${sortingRule.desc === true ? '-desc' : ''}`,
        value: `${sortingRule.id}`,
      });

    let path: string = '/contracts';
    if (queries.length >= 1) {
      const paramStrings: string[] = queries.map(
        (q) => `${encodeURIComponent(q.name)}=${encodeURIComponent(q.value)}`
      );
      path = `${path}?${paramStrings.join('&')}`;
    }

    try {
      return await API.get(getAPIName(), path, apiInit);
    } catch (e) {
      handleApiErrors(e, enqueueSnackbar);
      return [[], 0];
    }
  };

  // JSX.Element preprocessing /////////////////////////////////////////////////
  const columns: Column[] = [
    {
      Header: 'Waive',
      accessor: 'actions',
      Cell: (props: any) => (
        <Switch
          color="primary"
          size="small"
          checked={props.original.status === 'Waived'}
          onChange={() => updateContractStatus(props)}
        />
      ),
    },
    {
      Header: 'Contract #',
      accessor: 'contractnumber',
    },
    {
      Header: 'RO #',
      accessor: 'ro_number',
    },
    {
      accessor: (contract: Contract) =>
        contract.vehicle == null || contract.vehicle.licenseplate == null
          ? '—'
          : contract.vehicle.licenseplate,
      Header: 'License Plate',
      id: 'vehicle.licenseplate',
    },
    {
      accessor: (contract: Contract) =>
        contract.vehicle == null || contract.vehicle.state == null
          ? '—'
          : contract.vehicle.state,
      Header: 'License Plate State',
      id: 'vehicle.state',
    },
    {
      accessor: (contract: Contract) =>
        contract.dateout == null
          ? '—'
          : moment(contract.dateout).local().format('MM/DD/YYYY h:mm a'),
      Header: 'Loaner Out',
      id: 'dateout',
    },
    {
      accessor: (contract: Contract) =>
        contract.datein == null
          ? '—'
          : moment(contract.datein).local().format('MM/DD/YYYY h:mm a'),
      Header: 'Loaner In',
      id: 'datein',
    },
    {
      Header: 'SA',
      accessor: 'service_advisor_id',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: (props: any) => (props.value ? props.value : 'N/A'),
    },

    {
      Header: 'Auth Token',
      accessor: 'auth_token_id',
      Cell: (props: any) => (props.value ? 'Found' : 'Not Found'),
    },
  ];

  let pages: number =
    totalContractCount === 0 || pageState.pageSize === 0
      ? 0
      : Math.trunc(totalContractCount / pageState.pageSize);
  if (
    pages > 0 &&
    totalContractCount > pageState.pageSize &&
    totalContractCount % pageState.pageSize > 0
  )
    ++pages;

  // render ///////////////////////////////////////////////////////////////////
  return (
    <div>
      <Grid container>
        <Grid item xs={9}>
          <Typography variant="h4" align="left">
            Contracts
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            className={classes.spacedButton}
            onClick={() => navigate('contracts/new')}
          >
            Quick Contract
          </Button>
          <Button size="small" variant="outlined" onClick={handleCsvButton}>
            {!csvIsLoading ? 'Download Excel' : <CircularProgress size={24} />}
          </Button>
        </Grid>
      </Grid>
      <TopForm
        onChange={setTopFormState}
        onSearch={searchContracts}
        searchOptions={searchOptions}
        state={topFormState}
        statusOptions={statusOptions}
        authTokenStatusOptions={authTokenStatusOptions}
      />

      <Grid container>
        <Grid xs={12}>
          <ReactTable
            className="-striped -highlight"
            columns={columns}
            data={contracts}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            defaultSorted={defaultPageState.sortingRules}
            loading={isLoading}
            manual
            onFetchData={handleTableFetchData}
            page={pageState.page}
            pages={pages}
          />
        </Grid>
      </Grid>
    </div>
  );
}
