import React, { useState } from 'react';
import { Button, Grid, MenuItem, Select, TextField } from '@material-ui/core';
import { API } from 'aws-amplify';
import { getAPIName } from '../../config';
import useDebounce from '../helpers/useDebounce';
import { generateHandleChange } from '../../common/state-setter-generator';
import ServerPaginationGrid from '../vehicle-search.component';
import ServerPaginationGridRONumber from '../ronumber-search.component';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import { navigate } from '@reach/router';
import handleApiErrors from '../../common/handleApiErrors';
import { useSnackbar } from 'notistack';

type QuickContractState = {
  type: string;
  firstName: string;
  lastName: string;
  email: string;
  dealershipId?: number;
  vehicleId?: number;
  searchTerm: string;
  roNumberSearch: string;
  serviceAdvisorId?: string;
  roNumber?: string;
  authTokenId?: string;
  dateIn?: Date | null;
  dateOut?: Date | null;
  contractnumber?: string;
  contractid?: string;
};

const quickContractTypes: JSX.Element[] = ['Open', 'Closed'].map((type) => (
  <MenuItem key={type} value={type}>
    {type}
  </MenuItem>
));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacedButton: {
      marginRight: '2rem',
    },
  })
);

let ContractCreate = () => {
  const classes = useStyles();
  const [quickContractState, setQuickContractState] = useState<
    QuickContractState
  >({
    type: 'Open',
    firstName: '',
    lastName: '',
    email: '',
    dealershipId: undefined,
    vehicleId: undefined,
    searchTerm: '',
    roNumberSearch: '',
    dateOut: new Date(Date.now()),
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = generateHandleChange(
    quickContractState,
    setQuickContractState
  );

  const updateVehicle = (vehicleInfo: any) => {
    setQuickContractState({
      ...quickContractState,
      ...vehicleInfo,
    });
  };

  const updateContract = (contractInfo: any) => {
    setQuickContractState({
      ...quickContractState,
      ...contractInfo,
      dateIn:
        quickContractState.dateIn !== undefined
          ? quickContractState.dateIn
          : new Date(Date.now()),
    });
  };

  const debouncedSearchTerm = useDebounce(quickContractState.searchTerm, 500);

  const debouncedRONumber = useDebounce(quickContractState.roNumberSearch, 500);

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date(Date.now())
  );

  const handleDateChange = (date: Date | null, type: 'dateIn' | 'dateOut') => {
    setSelectedDate(date);
    handleChange(type)(date);
  };

  const saveContract = async () => {
    try {
      let response;
      if (quickContractState.type === 'Open') {
        response = await API.post(getAPIName(), '/contracts', {
          body: quickContractState,
        });
        enqueueSnackbar('Contract Opened!', { variant: 'success' });
      } else {
        response = await API.post(
          getAPIName(),
          `/contracts/${quickContractState.contractid}`,
          { body: quickContractState }
        );
        enqueueSnackbar('Contract Closed!', { variant: 'success' });
      }
      navigate('/contracts');
    } catch (err) {
      handleApiErrors(err, enqueueSnackbar);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <h2>Quick Contract</h2>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <Grid item xs={12} spacing={2} container alignItems="center">
            <Grid item xs={4} style={{ textAlign: 'left' }}>
              Contract Type
            </Grid>
            <Grid item xs={8}>
              <Select
                fullWidth
                margin="dense"
                onChange={(event: any) => {
                  handleChange('type')(event.target.value);
                }}
                value={quickContractState.type || 'Open'}
                required
              >
                {quickContractTypes}
              </Select>
            </Grid>
          </Grid>
          {quickContractState.type === 'Open' && (
            <>
              <Grid item xs={12} spacing={2} container alignItems="center">
                <Grid
                  item
                  xs={4}
                  className="label-margin"
                  style={{ textAlign: 'left' }}
                >
                  First Name
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    id="firstNameField"
                    placeholder="First Name"
                    margin="dense"
                    onChange={(event) =>
                      handleChange('firstName')(event.target.value)
                    }
                    required
                    value={quickContractState.firstName}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} spacing={2} container alignItems="center">
                <Grid
                  item
                  xs={4}
                  className="label-margin"
                  style={{ textAlign: 'left' }}
                >
                  Last Name
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    id="lastNameField"
                    placeholder="Last Name"
                    margin="dense"
                    onChange={(event) =>
                      handleChange('lastName')(event.target.value)
                    }
                    required
                    value={quickContractState.lastName}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} spacing={2} container alignItems="center">
                <Grid
                  item
                  xs={4}
                  className="label-margin"
                  style={{ textAlign: 'left' }}
                >
                  Email
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    id="emailField"
                    placeholder="Email"
                    margin="dense"
                    onChange={(event) =>
                      handleChange('email')(event.target.value)
                    }
                    required
                    value={quickContractState.email}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} spacing={2} container alignItems="center">
                <Grid
                  item
                  xs={4}
                  className="label-margin"
                  style={{ textAlign: 'left' }}
                >
                  Service Advisor ID
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    id="serviceAdvisorIdField"
                    placeholder="Service Advisor ID"
                    margin="dense"
                    onChange={(event) =>
                      handleChange('serviceAdvisorId')(event.target.value)
                    }
                    required
                    value={quickContractState.serviceAdvisorId}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} spacing={2} container alignItems="center">
                <Grid
                  item
                  xs={4}
                  className="label-margin"
                  style={{ textAlign: 'left' }}
                >
                  RO Number
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    id="roNumberField"
                    placeholder="RO Number"
                    margin="dense"
                    value={quickContractState.roNumber}
                    onChange={(event) =>
                      handleChange('roNumber')(event.target.value)
                    }
                    required
                  />
                </Grid>
              </Grid>
            </>
          )}
          {quickContractState.type === 'Closed' && (
            <Grid item xs={12} spacing={2} container alignItems="center">
              <Grid
                item
                xs={4}
                className="label-margin"
                style={{ textAlign: 'left' }}
              >
                RO Number
              </Grid>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  id="roNumberFieldSearch"
                  placeholder="RO Number"
                  margin="dense"
                  onChange={(event) =>
                    handleChange('roNumberSearch')(event.target.value)
                  }
                  required
                  value={quickContractState.roNumberSearch}
                />
              </Grid>
              {debouncedRONumber && (
                <Grid item xs={12}>
                  <ServerPaginationGridRONumber
                    searchTerm={debouncedRONumber}
                    handleChange={(contractInfo: any) =>
                      updateContract(contractInfo)
                    }
                  />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {quickContractState.type === 'Open' && (
            <Grid item xs={12} spacing={2} container alignItems="center">
              <Grid
                item
                xs={4}
                className="label-margin"
                style={{ textAlign: 'left' }}
              >
                Loaner Out
              </Grid>
              <Grid item xs={8} style={{ textAlign: 'left' }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    style={{ marginRight: '2rem' }}
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="dateOutDate"
                    label="Loaner Out"
                    value={selectedDate}
                    onChange={(e: any) => handleDateChange(e, 'dateOut')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <KeyboardTimePicker
                    margin="normal"
                    id="dateOutTime"
                    label="Date Out Time"
                    value={selectedDate}
                    onChange={(e: any) => handleDateChange(e, 'dateOut')}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          )}
          {quickContractState.type === 'Closed' && quickContractState.roNumber && (
            <Grid item xs={12} spacing={2} container alignItems="center">
              <Grid
                item
                xs={4}
                className="label-margin"
                style={{ textAlign: 'left' }}
                justify="flex-start"
              >
                Loaner In
              </Grid>
              <Grid item xs={8} style={{ textAlign: 'left' }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="dateInDate"
                    label="Loaner In"
                    value={selectedDate}
                    onChange={(e: any) => handleDateChange(e, 'dateIn')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <KeyboardTimePicker
                    margin="normal"
                    id="dateInTime"
                    label="Date In Time"
                    value={selectedDate}
                    onChange={(e: any) => handleDateChange(e, 'dateIn')}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          )}
          {quickContractState.type === 'Open' && (
            <Grid item xs={12} spacing={2} container alignItems="center">
              <Grid
                item
                xs={4}
                className="label-margin"
                style={{ textAlign: 'left' }}
              >
                Vehicle Search
              </Grid>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  id="vehicleSearchField"
                  placeholder="License Plate / VIN"
                  margin="dense"
                  onChange={(event) =>
                    handleChange('searchTerm')(event.target.value)
                  }
                  required
                  value={quickContractState.searchTerm}
                />
              </Grid>
              {debouncedSearchTerm && (
                <Grid item xs={12}>
                  <ServerPaginationGrid
                    searchTerm={debouncedSearchTerm}
                    handleChange={(vehicleInfo: any) => {
                      updateVehicle(vehicleInfo);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        spacing={2}
        container
        justify="center"
        style={{ paddingTop: '3rem' }}
      >
        <Button
          size="small"
          variant="outlined"
          color="primary"
          className={classes.spacedButton}
          onClick={() => navigate('/contracts')}
        >
          Cancel
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => saveContract()}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default ContractCreate;
