import { API } from 'aws-amplify';
import React, { useEffect } from 'react';

import NumberFormat, { NumberFormatValues } from 'react-number-format';

import {
  Button,
  Grid,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Switch,
} from '@material-ui/core';

import { getAPIName } from '../config';
import { generateHandleChange } from '../common/state-setter-generator';
import Dealership from '../_models/Dealership';
import { DealershipGroup } from '../_models/DealershipGroups';
import { States } from '../_models/states';
import { Timezones } from '../_models/Timezones';

function compareDealershipGroups(a: DealershipGroup, b: DealershipGroup) {
  if (a.name > b.name) return 1;
  if (b.name > a.name) return -1;
  return 0;
}

export interface DealershipFormProps {
  dealershipToEdit?: Dealership;
  handleSubmit(dealership: Dealership): void;
}

interface inputChangeState {
  readonly userInput: string;
}

export default function DealershipForm(props: DealershipFormProps) {
  // primary state ////////////////////////////////////////////////////////////
  const [inputChangeState, setInputChangeState] = React.useState<
    inputChangeState
  >({ userInput: '' });
  const [state, setState] = React.useState<Dealership>({} as Dealership);
  const [feeChecked, setFeeChecked] = React.useState(false);
  useEffect(() => {
    if (props.dealershipToEdit != null) {
      setState(props.dealershipToEdit);

      if (props.dealershipToEdit.tollFeeType) {
        setFeeChecked(true);
      } else {
        setFeeChecked(false);
      }
    } else setState({} as Dealership);
  }, [props.dealershipToEdit]);

  // fetch stuff //////////////////////////////////////////////////////////////
  // dealership groups
  const [dealershipGroups, setDealershipGroups] = React.useState<
    DealershipGroup[]
  >([]);
  const getDealershipGroups = async () => {
    try {
      const response: any = await API.get(
        getAPIName(),
        '/dealershipgroups',
        {}
      );
      setDealershipGroups(response.body);
    } catch (e) {
      console.log(e);
    }
  };
  React.useEffect(() => {
    getDealershipGroups();
  }, []);

  let firstTime = false;
  React.useEffect(() => {
    if (!feeChecked) {
      setState({ ...state, tollFee: '', tollFeeType: '', tollLineItem: '', tollFeeMax: '' });
    }
  }, [feeChecked]);

  // handlers /////////////////////////////////////////////////////////////////
  const handleFeeChange = () => {
    setFeeChecked(!feeChecked);
  };

  const handleChange = generateHandleChange(state, setState);
  const lmsversions = ['ARS', 'TSDv1', 'TSDv2', 'OnTrac'];
  const feeTypes = ['Percent', 'Flat Rate', 'Daily', 'Transactional'];
  // JSX.Element preprocessing /////////////////////////////////////////////////

  const feeTypeItems: JSX.Element[] = feeTypes.map((type: string) => {
    return (
      <MenuItem key={type} value={type}>
        {type}
      </MenuItem>
    );
  });

  const lmsVersionItems: JSX.Element[] = lmsversions.map((e) => (
    <MenuItem key={e} value={e}>
      {e}
    </MenuItem>
  ));

  const dealershipGroupMenuItems: JSX.Element[] = dealershipGroups
    .slice()
    .sort(compareDealershipGroups)
    .map((dg) => (
      <MenuItem key={dg.id} value={dg.id}>
        {dg.name}
      </MenuItem>
    ));
  const stateMenuItems = Object.values(States).map((s) => (
    <MenuItem key={s} value={s}>
      {s}
    </MenuItem>
  ));
  const timezoneMenuItems: JSX.Element[] = Object.values(Timezones).map((t) => (
    <MenuItem key={t} value={t}>
      {t}
    </MenuItem>
  ));

  // return ///////////////////////////////////////////////////////////////////
  return (
    <div className="dealership-profile-form">
      <Grid spacing={2} container alignItems="center">
        <Grid item xs={4} className="label-margin">
          Name
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="nameField"
            placeholder="Name"
            margin="dense"
            onChange={(event) => handleChange('name')(event.target.value)}
            required
            value={state.name || ''}
          />
        </Grid>
      </Grid>
      <Grid spacing={2} container alignItems="center">
        <Grid item xs={4}>
          Dealership Group
        </Grid>
        <Grid item xs={8}>
          <Select
            fullWidth
            margin="dense"
            onChange={(event: any) =>
              handleChange('dealershipgroupid')(event.target.value)
            }
            value={state.dealershipgroupid || 0}
          >
            {dealershipGroupMenuItems}
          </Select>
        </Grid>
      </Grid>
      <Grid spacing={2} container alignItems="center">
        <Grid item xs={4}>
          Timezone
        </Grid>
        <Grid item xs={8}>
          <Select
            fullWidth
            value={state.timezone || ''}
            onChange={(event: any) =>
              handleChange('timezone')(event.target.value as string)
            }
            margin="dense"
          >
            {timezoneMenuItems}
          </Select>
        </Grid>
      </Grid>
      <Grid spacing={2} container alignItems="center">
        <Grid item xs={4} className="label-margin">
          Address
        </Grid>
        <Grid item xs={8}>
          <TextField
            placeholder="Line 1"
            fullWidth
            id="address"
            margin="dense"
            onChange={(event) =>
              handleChange('addressline1')(event.target.value)
            }
            value={state.addressline1 || ''}
          />
        </Grid>
      </Grid>
      <Grid container justify="flex-end" spacing={2}>
        <Grid item xs={8}>
          <TextField
            placeholder="Line 2"
            fullWidth
            id="address"
            margin="dense"
            onChange={(event) =>
              handleChange('addressline2')(event.target.value)
            }
            value={state.addressline2 || ''}
          />
        </Grid>
      </Grid>
      <Grid spacing={2} container alignItems="center" alignContent="center">
        <Grid item xs={4}>
          City
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            placeholder="City"
            id="city"
            margin="dense"
            onChange={(event) => handleChange('city')(event.target.value)}
            value={state.city || ''}
          />
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth margin="dense">
            <Select
              id="state"
              value={state.state || ''}
              // variant="outlined"
              onChange={(event: any) =>
                handleChange('state')(event.target.value)
              }
            >
              {stateMenuItems}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="zipcode"
            margin="dense"
            onChange={(event) => handleChange('zipcode')(event.target.value)}
            placeholder="Zip"
            value={state.zipcode || ''}
          />
        </Grid>
      </Grid>
      <Grid spacing={2} container alignItems="center">
        <Grid item xs={4}>
          Phone
        </Grid>
        <Grid item xs={8}>
          <NumberFormat
            customInput={TextField}
            format="+1 (###) ###-####"
            mask="_"
            fullWidth
            id="phone"
            margin="dense"
            onValueChange={(values: NumberFormatValues) =>
              handleChange('phone')(values.value)
            }
            placeholder="Phone"
            value={state.phone || ''}
          />
        </Grid>
      </Grid>
      <Grid spacing={2} container alignItems="center">
        <Grid item xs={4}>
          Email
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="email"
            margin="dense"
            onChange={(event) =>
              handleChange('emailaddress')(event.target.value)
            }
            placeholder="Email"
            value={state.emailaddress || ''}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
          Account Number
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="account-number"
            margin="dense"
            onChange={(event) =>
              handleChange('accountnumber')(event.target.value)
            }
            placeholder="Account Number"
            value={state.accountnumber || ''}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
          Location Code
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="location-code"
            margin="dense"
            onChange={(event) =>
              handleChange('locationcode')(event.target.value)
            }
            placeholder="Location Code"
            value={state.locationcode || ''}
          />
        </Grid>
      </Grid>
      <Grid spacing={2} container alignItems="center">
        <Grid item xs={4}>
          LMS
        </Grid>
        <Grid item xs={8}>
          <Select
            fullWidth
            margin="dense"
            onChange={(event: any) =>
              handleChange('lmsversion')(event.target.value)
            }
            value={state.lmsversion || 0}
          >
            {lmsVersionItems}
          </Select>
        </Grid>
      </Grid>
      <Grid spacing={2} container alignItems="center">
        <Grid item xs={4}>
          Add Toll Fee
        </Grid>
        <Grid item container xs={8} alignContent="flex-start">
          <Switch
            checked={feeChecked}
            onChange={handleFeeChange}
            name="checked"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </Grid>
        {feeChecked && (
          <Grid spacing={2} container>
            <Grid item xs={4}>
              Fee Type
            </Grid>
            <Grid item xs={8}>
              <Select
                fullWidth
                margin="dense"
                onChange={(event: any) =>
                  handleChange('tollFeeType')(event.target.value)
                }
                value={state.tollFeeType}
              >
                {feeTypeItems}
              </Select>
            </Grid>
            <Grid item xs={4}>
            {state.tollFeeType} Fee Amount
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="tollFee"
                margin="dense"
                type="number"
                inputProps={{ min: 0 }}
                onChange={(event) =>
                  handleChange('tollFee')(event.target.value)
                }
                placeholder="Toll Fee Amount"
                value={state.tollFee}
              />
            </Grid>
            <Grid item xs={4}>
            {state.tollFeeType} Fee Description
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="tollLineItem"
                margin="dense"
                // type="number"
                inputProps={{ min: 0 }}
                onChange={(event) =>
                  handleChange('tollLineItem')(event.target.value)
                }
                placeholder="Toll Fee Description"
                value={state.tollLineItem}
              />
            </Grid>
          </Grid>
        )}
        {feeChecked && (state.tollFeeType === 'Daily' ||  state.tollFeeType === 'Transactional') && (
          <Grid spacing={2} container>
            <Grid item xs={4}>
              {state.tollFeeType} Fee Max
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="tollFeeMax"
                margin="dense"
                type="number"
                inputProps={{ min: 0 }}
                onChange={(event) =>
                  handleChange('tollFeeMax')(event.target.value)
                }
                placeholder="Toll Fee Max Amount"
                value={state.tollFeeMax}
              />
            </Grid>
            </Grid>
            )}
      </Grid>
      <Grid spacing={2} container alignItems="center">
        <Grid item xs={4}>
          Active Invoice
        </Grid>
        <Grid item container xs={8} alignContent="flex-start">
          <Switch
            checked={state.active_invoice ? state.active_invoice : false}
            onChange= {(event) => handleChange('active_invoice')(event.target.checked)}
            name="checked"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            value = {state.active_invoice || false}
          />
        </Grid>
      </Grid>
      <Grid spacing={2} container alignItems="center">
        <Grid item xs={4}>
          Active Payment
        </Grid>
        <Grid item container xs={8} alignContent="flex-start">
          <Switch
            checked={state.active_payments ? state.active_payments : false}
            onChange= {(event) => handleChange('active_payments')(event.target.checked)}
            name="checked"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            value = {state.active_payments || false}
          />
        </Grid>
      </Grid>
      <Grid spacing={2} container alignItems="center" justify="flex-end">
        <Button
          className="blue-button"
          style={{ marginTop: '2rem' }}
          onClick={() => props.handleSubmit(state)}
          variant="outlined"
        >
          Save
        </Button>
      </Grid>
    </div>
  );
}
