import React, { useEffect, useState, ChangeEvent } from 'react';
import { API } from 'aws-amplify';
import { library } from '@fortawesome/fontawesome-svg-core';
import handleApiErrors from '../../common/handleApiErrors';
import { useSnackbar } from 'notistack';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getAPIName } from '../../config';
import SetupForm from '../Setup.form';
import DealershipSelect from '../dealership-select.component';

import {
    Paper,
    Typography,
    Divider,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button
} from '@material-ui/core';

import {
    faEdit,
    faCalendarAlt,
    faAngleLeft,
    faAngleRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faCheck,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
library.add(
    faEdit,
    faCalendarAlt,
    faAngleLeft,
    faAngleRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faCheck,
    faTimes
);


export default function AuthorisePayment() {
    const { enqueueSnackbar } = useSnackbar();
    const [dealershipId, setdealershipId] = useState(0)
    const [contractNumber, setContractNumber] = useState('')
   
    const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setContractNumber(event.target.value);
    };

    const handleCheckoutPayment = () => {
        if (dealershipId && contractNumber) {
            console.log(dealershipId, contractNumber)
            getSetupIntent()
        }
        else
            alert("enter dealer and contractnumber")
    }

    const [setupIntentClientSecret, setSetupIntentClientSecret] = useState('')
    const stripePromise = loadStripe('pk_live_51H7RsdJp6SVLhNZmDxSuV3O4BTAE5YkOV1RerH211atGSJHJpA5fiTCisDScJn1lLlocIYGjp6RPFqt0qbRbLtPn00ncjRd5nm');
    var opt = {
        clientSecret: setupIntentClientSecret,
        appearance: {/*...*/ },
    };
    const getSetupIntent = async () => {
        try {
            const response: any = await API.post(getAPIName(), '/api/v1/payment/setup_customer',
                {
                    body: {
                        dealership_id: dealershipId,
                        referencenumber: contractNumber,
                        paymentgateway: 'Stripe',
                        paymentmethod: ['card'],
                        pgw_version:'V2'
                    }
                });
            if (response.error) { 
                alert(response.error)
            }   
            else{
                setSetupIntentClientSecret(response.setup_intent_clientSecret)
            }
        }
        catch (e) {
            handleApiErrors(e, enqueueSnackbar, 'setup intent client');
        }

    }

    return (
        <div>
            <Grid container>
                <Grid item xs={9}>
                    <Typography variant="h4" align="left">
                        AUTHENTICATE PAYMENT FOR TOLLS 
                    </Typography>
                </Grid>
            </Grid>

            <Grid item xs={6} md={3}>
                <FormControl style={{ width: '100%' }}>
        
                    {/* We were using "zero width space" AKA \\u200B as a hidden break here. */}
                    {/* Adding explicit breaks until we fix with CSS */}
                    <br />
                    <br />
                    <DealershipSelect
                        dealershipId={dealershipId}
                        onChangeDealership={setdealershipId}
                    />

                </FormControl>
            </Grid>

            <Grid item xs={12} className="contracts-search">
                <Typography variant="h3" align={'left'}>
                    <TextField
                        onChange={handleSearchInputChange}
                        placeholder="Rental Agreement / Contract Number"
                        style={{ marginTop: 60, width: '100%' }}
                        value={contractNumber}
                    />
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <br />
                <br />
                <br />
                {
                    !setupIntentClientSecret &&
                        <Button variant="contained" color="primary" onClick={() => handleCheckoutPayment()}>
                            START AUTH
                        </Button>
                }
            </Grid>

            <Grid item xs={12} className="">
                <br />
                <br />
                <br />
                {setupIntentClientSecret &&
                    <Elements stripe={stripePromise} options={opt} >
                        <SetupForm dealershipId={dealershipId} contractNumber={contractNumber} />
                    </Elements>
                }
            </Grid>

        </div>
    );
}

