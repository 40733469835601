import * as React from 'react';
import { AmplifyAuthContext } from '../App';
import Grid from '@material-ui/core/Grid';
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getAPIName } from '../config';
import { API } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import ConfirmationPopup from '../components/ConfirmationPopup'

export default function UsersProfileForm(props: any) {
  const [state, setState] = React.useState<any>(props.user);
  const [dealerships, setDealerships] = React.useState<any>([]);
  const [dealershipGroups, setDealershipGroups] = React.useState<any>([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);

  const currentAuthenticatedUser: any = React.useContext(AmplifyAuthContext);
  
  const userRole: string =
    currentAuthenticatedUser == null ||
    currentAuthenticatedUser.attributes == null ||
    currentAuthenticatedUser.attributes.given_name == null
      ? 'Individual'
      : currentAuthenticatedUser.attributes.given_name;

  const getDealerships = () => {
    let apiName = getAPIName();
    let path = '/dealerships';
    let myInit = {};
    API.get(apiName, path, myInit)
      .then(response => {
        const results = response.body;
        setDealerships(results);
      })
      .catch(error => {
        console.log(error); 
      });
  };

  const getDealershipGroups = () => {
    let apiName = getAPIName();
    let path = '/dealershipgroups';
    let myInit = {};
    API.get(apiName, path, myInit)
      .then(response => {
        const results = response.body;
        setDealershipGroups(results);
      })
      .catch(error => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    setState(props.user);
    if (props.user.userType === 'Group') {
      getDealershipGroups();
    } else if (props.user.userType === 'Individual') {
      getDealerships();
    }
  }, [props.user]);

  const handleUserTypeChange = (e: any) => {
    let type = e.target.value;
    setState({ ...state, userType: type });
    if (type === 'Individual') {
      getDealerships();
    } else if (type === 'Group') {
      getDealershipGroups();
    }
  };

  const handleChange = (e: any) => {
    setState({ ...state, [e.target.id]: e.target.value });
  };

  const handleValueChange = (id: string, value:string) => {
    setState({ ...state, [id]: value });
  }

  const handleDealershipDropdown = (e: any) => {
    setState({ ...state, roleId: e.target.value.toString() });
  };

  const handleDealershipGroupDropdown = (e: any) => {
    setState({ ...state, roleId: e.target.value.toString() });
  };

  const handleSubmit = () => {
    if (state.userType === undefined || state.userType === 'Admin') state.roleId = '0';
    if (state.userType === undefined) state.userType = 'Individual';
    props.handleSubmit(state);
  };

  const handleDelete = () => {
    if (state.userType === undefined || state.userType === 'Admin') state.roleId = '0';
    if (state.userType === undefined) state.userType = 'Individual';
    props.handleDelete(state);
  };
  
  const toggleConfirmation = () => {
    setShowDeleteConfirmation(!showDeleteConfirmation);
  }

  return (
    <div className="users-profile-form">
      <Grid spacing={2} container>
        <Grid item xs={4} className="label-margin">
          Name
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="name"
            margin="dense"
            placeholder="Name"
            value={state.name || ''}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid spacing={2} container>
        <Grid item xs={4}>
          Phone
        </Grid>
        <Grid item xs={8}>
          <NumberFormat
            customInput={TextField}
            format="+1 (###) ###-####" mask="_"
            id="phone_number"
            placeholder="Phone"
            margin="dense"
            fullWidth
            value={state.phone_number}
            onValueChange={(values: NumberFormatValues) => handleValueChange('phone_number', values.value)}
          />
        </Grid>
      </Grid>
      <Grid spacing={2} container>
        <Grid item xs={4}>
          Email
        </Grid>
        <Grid item xs={8}>
          <TextField
            placeholder="Email"
            id="email"
            fullWidth
            margin="dense"
            value={state.email}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid spacing={2} container>
        <Grid item xs={4}>
          User Type
        </Grid>
        <Grid item xs={8}>
          <FormControl component="fieldset">
            <RadioGroup
              className="radios"
              aria-label="position"
              name="position"
              value={state.userType}
              onChange={handleUserTypeChange}
              row
            >
              {/* Need logic for handling what type of use you are to see what you can add */}
              {userRole === 'Admin' && 
              <FormControlLabel
                value="Admin"
                control={
                  <Radio color="primary" checked={state.userType === 'Admin'} />
                }
                label="Admin"
                labelPlacement="top"
              />
              }

              <FormControlLabel
                value="Group"
                control={
                  <Radio color="primary" checked={state.userType === 'Group'} />
                }
                label="Group"
                labelPlacement="top"
              />
              <FormControlLabel
                value="Individual"
                control={
                  <Radio
                    color="primary"
                    checked={state.userType === 'Individual'}
                  />
                }
                label="Individual"
                labelPlacement="top"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      {state.userType === 'Group' && (
        <Grid spacing={2} container>
          <Grid item xs={4}>
            Dealership Group
          </Grid>
          <Grid item xs={8}>
            <FormControl style={{ width: '100%' }}>
              <Select
                value={state.roleId}
                onChange={handleDealershipGroupDropdown}
              >
                <MenuItem key={'0'} value={'default'}>
                  Select Dealership Group
                </MenuItem>
                {dealershipGroups &&
                  dealershipGroups.map((dealershipGroup: any) => {
                    return (
                      <MenuItem
                        key={dealershipGroup.id}
                        value={dealershipGroup.id}
                      >
                        {dealershipGroup.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
      {state.userType === 'Individual' && (
        <Grid spacing={2} container>
          <Grid item xs={4}>
            Dealership
          </Grid>
          <Grid item xs={8}>
            <FormControl style={{ width: '100%' }}>
              <Select value={state.roleId} onChange={handleDealershipDropdown}>
                <MenuItem key={'0'} value={'default'}>
                  Select Dealership
                </MenuItem>
                {dealerships &&
                  dealerships.map((dealership: any) => {
                    return (
                      <MenuItem key={dealership.id} value={dealership.id}>
                        {dealership.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}

      <Grid container justify="flex-end" direction="row">
      <Button
          variant="outlined"
          className="blue-button margin-right"
          onClick={() => setShowDeleteConfirmation(true)}
        >
          Delete
        </Button>
        <Button
          variant="outlined"
          className="blue-button"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </Grid>
      <ConfirmationPopup confirmedAction={handleDelete} isOpen={showDeleteConfirmation} text="Are you sure you want to delete this user?" toggleOpen={toggleConfirmation}/>
    </div>
  );
}
