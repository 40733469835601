import React, { useEffect, useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Button } from '@material-ui/core';
import { getAPIName } from '../config';
import { API } from 'aws-amplify';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttondiv: {
      color: 'white',
      padding: '6px',
      background: 'darkgrey',
      '&:hover': {
        background: "black",
      }
    }
  })
);


const SetupForm = (props: any) => {
  const classes = useStyles();
  const stripe: any = useStripe();
  const elements: any = useElements();
  const [clickedFlag, setClickedFlag] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const deleteAuthToken = async () => {
    let path = '/api/v1/payment/setup_customer';
    try {
      if (props.dealershipId && props.contractNumber) {
        const response: any = await API.del(getAPIName(), path,
          {
            body: {
              dealership_id: props.dealershipId,
              referencenumber: props.contractNumber
            }
          });
      }
    } catch (e) {
      console.log(e);
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setClickedFlag(true)
    if (!stripe || !elements) {
      return;
    }
    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: window.location.protocol + "//" + window.location.hostname + "/authorisepaymentsuccess" + "?dealer=" + props.dealershipId + "&contract=" + props.contractNumber,
      }
    });
    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      deleteAuthToken();
      setClickedFlag(false);
      window.location.replace(window.location.protocol + "//" + window.location.hostname + "/authorisepaymentfail?message=" + error.message)

    } else {
      // Your customer will be redirected to your `return_url`. For some payment methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement/>
      <Button variant="contained" color="primary" disabled={!stripe || clickedFlag} type="submit">
        AUTHORIZE
      </Button>
      {/* Show error message to your customers */}
      {errorMessage && <><br />
        <div>
          <h3 style={{ color: "red" }}>{errorMessage}</h3>
        </div></>}
    </form >
  )
};

export default SetupForm;