import Amplify from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react'; // or 'aws-amplify-react-native';
import { SnackbarProvider } from 'notistack';
import React, { createContext } from 'react';

import './App.scss';
import awsconfig from './config';
import MiniDrawer from './components/NavFrame';
import useAmplifyAuth from './common/useAmplifyAuth';

Amplify.configure(awsconfig);

export const AmplifyAuthContext = createContext(null);
export const AmplifyAuthSignoutContext = createContext(async () => { return; });

function SubApp() {
  return (
    <div className="App">
      <SnackbarProvider
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        key="bottom,right"
        maxSnack={5}
      >
        <MiniDrawer />
      </SnackbarProvider>
    </div>
  );
}

const AuthApp = withAuthenticator(SubApp);

export default function App() {
  const { state: { user }, handleSignout } = useAmplifyAuth();

  return (
    <AmplifyAuthContext.Provider value={user}>
      <AmplifyAuthSignoutContext.Provider value={handleSignout}>
        <AuthApp />
      </AmplifyAuthSignoutContext.Provider>
    </AmplifyAuthContext.Provider>
  );
}